
import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import TopNav from 'components/Navbars/TopNav-7';
import Navbar from 'components/Navbars/ITCreativeNav';
import Header from 'components/Style2/Header';
import Footer from 'components/Saas/Footer';
import { Link } from 'gatsby';


const Mudarabah = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current, true);
  }, [navbarRef]);

  return (
    <MainLayout>
      {/* <TopNav style="5" /> */}
      <Navbar navbarRef={navbarRef} />
      <Header page="Mudarabah" title="Mudarabah" />
      <main className="services-details-page style-5">
      <section className="ser-details section-padding overflow-hidden">
      <div className="container">
        <div className="content">
          <div className="row gx-5">
            <div className="col-lg-8">
              <div className="main-info">
                <div className="main-img img-cover">
                  <img src="/assets/img/services/market.webp" alt="" />
                </div>
                <h3 className="text-capitalize mb-20"> Mudarabah </h3>
                <p className="mb-10">
                Looking for Sharia-compliant investments? Our Mudarabah investment products offer ethical investment opportunities with shared profits. Ideal for individuals seeking interest-free returns while adhering to Islamic financial principles.</p>
                {/* <h4 className="mt-5 mb-4"> Requirements </h4>
                <p className="mt-10"> <i className="fas fa-check-circle color-blue5 me-2"></i> <strong className="color-000"> 6 Months Bank Statement: </strong> We will need the last six months account statement of your business. </p>
                <p className="mt-10"> <i className="fas fa-check-circle color-blue5 me-2"></i> <strong className="color-000"> Collateral Documents: </strong> Original collateral documents are also needed. </p>
                <p className="mt-10"> <i className="fas fa-check-circle color-blue5 me-2"></i> <strong className="color-000"> Valid Identification: </strong> We will need your passport and any valid means of identification for KYC purposes.</p> */}

              </div>
            </div>
            <div className="col-lg-4">
              <div className="side-links mt-5 mt-lg-0">
                <div className="links-card mb-40">
                  <h5> Services</h5>
                  <ul>
                    <li>
                      <a href="/tenhours"> <i className="far fa-angle-right icon"></i> 10 Hours Prompt Loan </a>
                    </li>
                    <li>
                      <a href="/mtplus-loan"> <i className="far fa-angle-right icon"></i> Mutual Plus Loan </a>
                    </li>
                    <li>
                      <a href="/mt-loans"> <i className="far fa-angle-right icon"></i> MT loans </a>
                    </li>
                    <li>
                      <a href="#"> <i className="far fa-angle-right icon"></i> SME Loans </a>
                    </li>
                    <li>
                      <a href="/ippis-loan"> <i className="far fa-angle-right icon"></i> IPPIS Loans </a>
                    </li>
                    <li>
                      <a href="/carforcash"> <i className="far fa-angle-right icon"></i>Car4Cash  Loan</a>
                    </li>
                    <li>
                      <a href="/sme-loans"> <i className="far fa-angle-right icon"></i> SME Loans </a>
                    </li>
                    <li>
                      <a href="/treasury-note"> <i className="far fa-angle-right icon"></i>Treasury Note </a>
                    </li>
                    <li>
                      <a href="/fixed-deposit"> <i className="far fa-angle-right icon"></i> Fix Deposit </a>
                    </li>
                    <li>
                      <a href="/mudarabah"> <i className="far fa-angle-right icon"></i> Mudarabah </a>
                    </li>
                    <li>
                      <a href="/mt-green-solar"> <i className="far fa-angle-right icon"></i> Mt Green Solar </a>
                    </li>
                  </ul>
                </div>
                <div className="banner1">
                  <div className="title">
                    <h6> Call To Action </h6>
                    <h3> Make An Custom Request </h3>
                  </div>
                  <Link to="/contact" className="butn bg-white rounded-pill hover-blue5">
                    <span> Get A Quote <i className="far fa-long-arrow-right ms-2"></i> </span>
                  </Link>
                  <img src="/assets/img/mob1.png" alt="" className="mob" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
       
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
     <title> Islamic Compliant Investment with Zero Interest</title>
     <meta name="description" content="Grow your wealth, the Islamic way. Invest in Mudarabah today. " />
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default Mudarabah;